import LayoutPage from '@/view/layout/Layout'

export default [{
    path: '/region',
    component: LayoutPage,
    children: [{
        path: 'list/region',
        name: 'list-region',
        component: () => import('@/view/region/ListRegion'),
    }, {
        path: 'list/subRegion',
        name: 'list-sub-region',
        component: () => import('@/view/region/ListSubRegion'),
        }]
}]