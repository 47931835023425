import LayoutPage from '@/view/layout/Layout'

export default [{
    path: '/multimedia',
    component: LayoutPage,
    children: [{
        path: 'list/procesoElectoralMultimedia',
        name: 'list-proceso-electoral-multimedia',
        component: () => import('@/view/multimedia/ListProcesoElectoralMultimedia'),
    }]
}]