<template>
  <!--<ul class="menu-nav">
    <li class="menu-item menu-item-active">
      <a class="menu-link">
        <span class="menu-text"> Primera Vuelta</span>
      </a>
    </li>
  </ul>-->
  <div>
    <span class="badge badge-light">{{ $session.get("ProcesoElectoral") }}</span>
  </div>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
