import Vue from "vue";
import App from "./App.vue";
import NotifyHub from './signalr/notify-hub'
import router from "./router/index";
import VueGtag from "vue-gtag";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import MockService from "@/core/mock/mock.service";
// import { VERIFY_AUTH } from "@/core/services/store/auth.module";
// import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";


Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";

import globals from './shared/globals'
import baseMixin from './shared/base-mixin'

// API service init
//ApiService.init();

// Remove this to disable mock API
//MockService.init();
/*
router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
*/

import VueResource from 'vue-resource'
import VueSession from 'vue-session'

Vue.use(VueResource);
Vue.use(NotifyHub, {
  url: process.env.NODE_ENV === 'production' ? '//voluntariadotransparencia.com/segel-api/notify-hub/' : 'https://localhost:44398/notify-hub/',
});
Vue.use(VueSession, {
  persist: true
})

Vue.http.interceptors.push(function (request) {

  if (this.$session.get("jwt") != null) {
    request.headers.set('X-CSRF-TOKEN', 'TOKEN');
    request.headers.set('Authorization', 'Bearer ' + this.$session.get("jwt"));
  }
});


Vue.use(VueGtag, {
  config: { id: "G-E1FX5SX3YT" }
}, router);


Vue.mixin({
  data: globals
})

Vue.mixin(baseMixin())

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
