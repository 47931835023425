import LoginPage from "@/view/account/Login";

export default [
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/app-login-router/:email/:password/:url1/:url2",
    name: "app-login-router",
    component: () => import("@/view/account/AppLoginRouter"),
  },
];
