var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav pt-2"},[_vm._m(0),(_vm.hasRole(['USU', 'ADM']))?_c('router-link',{attrs:{"to":"/home/dashboard-user"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-home fa-fw justify-content-center mr-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Inicio")])])])]}}],null,false,923168371)}):_vm._e(),(_vm.hasRole(['ADM']))?_c('router-link',{attrs:{"to":"/home/dashboard-admin"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-tachometer-alt fa-fw justify-content-center mr-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Seguimiento")])])])]}}],null,false,699655200)}):_vm._e(),(_vm.hasRole(['USU', 'ADM']))?_c('router-link',{attrs:{"to":"/location/list/localVotacionUsuario"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-clipboard-check fa-fw justify-content-center mr-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Mis Locales")])])])]}}],null,false,3876026364)}):_vm._e(),(_vm.hasRole(['USU', 'ADM']))?_c('router-link',{attrs:{"to":"/location/list/localVotacionUsuarioTurno"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-clock fa-fw justify-content-center mr-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Mis Turnos")])])])]}}],null,false,352078185)}):_vm._e(),(_vm.hasRole(['ADM', 'USU']))?_c('router-link',{attrs:{"to":"/multimedia/list/procesoElectoralMultimedia"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-image fa-fw justify-content-center mr-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Imágenes")])])])]}}],null,false,3410054063)}):_vm._e(),(_vm.hasRole(['USU', 'ADM']))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/incident/list') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(2),_c('router-link',{attrs:{"to":"/incident/list/localVotacionActosViolencia"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-hand-paper"}),_c('span',{staticClass:"menu-text"},[_vm._v("Actos de Violencia")])])])]}}],null,false,4234739439)}),_c('router-link',{attrs:{"to":"/incident/list/localVotacionIncidenteOtros"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-bell"}),_c('span',{staticClass:"menu-text"},[_vm._v("Otros")])])])]}}],null,false,273254450)})],1)])]):_vm._e(),(_vm.hasRole(['USU', 'ADM']))?_c('router-link',{attrs:{"to":"/electoral/list/PropagandaElectoral"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-id-badge fa-fw justify-content-center mr-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Propaganda Electoral")])])])]}}],null,false,3462186856)}):_vm._e(),(_vm.hasRole(['ADM', 'USU']))?_c('router-link',{attrs:{"to":"/spending/list/ElectoralProcessSpendingUser"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-coins fa-fw justify-content-center mr-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Mis Cuentas")])])])]}}],null,false,187092131)}):_vm._e(),(_vm.hasRole(['ADM']))?_c('li',{staticClass:"menu-section mt-2"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Administración")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.hasRole(['ADM']))?_c('router-link',{attrs:{"to":"/process/list/ElectoralProcess"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-vote-yea fa-fw justify-content-center mr-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Procesos Electorales")])])])]}}],null,false,323154358)}):_vm._e(),(_vm.hasRole(['ADM']))?_c('router-link',{attrs:{"to":"/location/list/localVotacion"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-building fa-fw justify-content-center mr-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Locales de Votación")])])])]}}],null,false,1601749504)}):_vm._e(),(_vm.hasRole(['ADM']))?_c('router-link',{attrs:{"to":"/user/list/usuarios"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-users fa-fw justify-content-center mr-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Usuarios")])])])]}}],null,false,94875840)}):_vm._e(),(_vm.hasRole(['ADM']))?_c('router-link',{attrs:{"to":"/spending/list/ElectoralProcessSpendingAdministration"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-coins fa-fw justify-content-center mr-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Rendición de Cuentas")])])])]}}],null,false,2622521558)}):_vm._e(),(_vm.hasRole(['ADM']))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/reports') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(3),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(4),_c('router-link',{attrs:{"to":"/reports/dimension/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Dimensiones")])])])]}}],null,false,418459575)}),_c('router-link',{attrs:{"to":"/reports/data/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Gráficos Genéricos")])])])]}}],null,false,3133374804)})],1)])]):_vm._e(),(_vm.hasRole(['ADM']))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/region/list') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(5),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(6),_c('router-link',{attrs:{"to":"/region/list/region"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Regiones")])])])]}}],null,false,86181759)}),_c('router-link',{attrs:{"to":"/region/list/subRegion"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Subregiones")])])])]}}],null,false,678547291)})],1)])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section mt-0"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Jornada Electoral")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon fa fa-exclamation-triangle fa-fw justify-content-center mr-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Incidentes")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Incidentes")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon fa fa-chart-pie fa-fw justify-content-center mr-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Reportes")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Reportes")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon fa fa-map-marker-alt fa-fw justify-content-center mr-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Regiones")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Regiones")])])])
}]

export { render, staticRenderFns }