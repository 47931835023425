import LayoutPage from '@/view/layout/Layout'

export default [{
    path: '/location',
    component: LayoutPage,
    children: [{
        path: 'list/localVotacion',
        name: 'local-votacion-list',
        component: () => import('@/view/location/ListLocalVotacion'),
        /*props: (route) => {
            debugger;
            return {
                localVotacionId: Number.parseInt(route.params.localVotacionId, 10),
                procesoElectoralId: Number.parseInt(route.params.procesoElectoralId, 10),
            }
        }*/
    }, {
        path: 'list/localVotacionUsuario',
        name: 'local-votacion-usuario-list',
        component: () => import('@/view/location/ListLocalVotacionUsuario'),
        }, {
        path: 'list/localVotacionUsuarioTurno',
        name: 'local-votacion-usuario-turno-list',
        component: () => import('@/view/location/ListLocalVotacionUsuarioTurno'),
    }]
}]