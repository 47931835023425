<template>
  <ul class="menu-nav pt-2">
    <!--<router-link
      to="/sheet/local/edit/2/3"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Ficha de Observación</span>
        </a>
      </li>
    </router-link>-->

    <li class="menu-section mt-0">
      <h4 class="menu-text">Jornada Electoral</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      to="/home/dashboard-user"
      v-if="hasRole(['USU', 'ADM'])"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-home fa-fw justify-content-center mr-2"></i>
          <span class="menu-text">Inicio</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/home/dashboard-admin"
      v-if="hasRole(['ADM'])"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i
            class="menu-icon fa fa-tachometer-alt fa-fw justify-content-center mr-2"
          ></i>
          <span class="menu-text">Seguimiento</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/location/list/localVotacionUsuario"
      v-if="hasRole(['USU', 'ADM'])"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i
            class="menu-icon fa fa-clipboard-check fa-fw justify-content-center mr-2"
          ></i>
          <span class="menu-text">Mis Locales</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/location/list/localVotacionUsuarioTurno"
      v-if="hasRole(['USU', 'ADM'])"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa fa-clock fa-fw justify-content-center mr-2"></i
          ><!-- clock -->
          <span class="menu-text">Mis Turnos</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/multimedia/list/procesoElectoralMultimedia"
      v-if="hasRole(['ADM', 'USU'])"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i
            class="menu-icon fa fa-image fa-fw justify-content-center mr-2"
          ></i>
          <span class="menu-text">Imágenes</span>
        </a>
      </li>
    </router-link>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      v-if="hasRole(['USU', 'ADM'])"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/incident/list') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i
          class="menu-icon fa fa-exclamation-triangle fa-fw justify-content-center mr-2"
        ></i>
        <span class="menu-text">Incidentes</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Incidentes</span>
            </span>
          </li>

          <router-link
            to="/incident/list/localVotacionActosViolencia"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon fa fa-hand-paper"></i>
                <span class="menu-text">Actos de Violencia</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/incident/list/localVotacionIncidenteOtros"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon fa fa-bell"></i>
                <span class="menu-text">Otros</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <router-link
      to="/electoral/list/PropagandaElectoral"
      v-if="hasRole(['USU', 'ADM'])"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i
            class="menu-icon fa fa-id-badge fa-fw justify-content-center mr-2"
          ></i>
          <span class="menu-text">Propaganda Electoral</span>
        </a>
      </li>
    </router-link>

    <!--Cuentas Usuarios-->
    <router-link
      to="/spending/list/ElectoralProcessSpendingUser"
      v-if="hasRole(['ADM', 'USU'])"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i
            class="menu-icon fa fa-coins fa-fw justify-content-center mr-2"
          ></i>
          <span class="menu-text">Mis Cuentas</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section mt-2" v-if="hasRole(['ADM'])">
      <h4 class="menu-text">Administración</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      to="/process/list/ElectoralProcess"
      v-if="hasRole(['ADM'])"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i
            class="menu-icon fa fa-vote-yea fa-fw justify-content-center mr-2"
          ></i>
          <span class="menu-text">Procesos Electorales</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/location/list/localVotacion"
      v-if="hasRole(['ADM'])"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i
            class="menu-icon fa fa-building fa-fw justify-content-center mr-2"
          ></i>
          <span class="menu-text">Locales de Votación</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/user/list/usuarios"
      v-if="hasRole(['ADM'])"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i
            class="menu-icon fa fa-users fa-fw justify-content-center mr-2"
          ></i>
          <span class="menu-text">Usuarios</span>
        </a>
      </li>
    </router-link>

    <!--Cuentas Administracion-->
    <router-link
      to="/spending/list/ElectoralProcessSpendingAdministration"
      v-if="hasRole(['ADM'])"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i
            class="menu-icon fa fa-coins fa-fw justify-content-center mr-2"
          ></i>
          <span class="menu-text">Rendición de Cuentas</span>
        </a>
      </li>
    </router-link>

    <!--
    <sidenav-menu :active="isMenuActive('/reports/')" :open="isMenuOpen('/reports/')" icon="fa fa-table">
      <template slot="link-text">Reportes</template>
      <sidenav-router-link to="/reports/dimension/list" :exact="true">Por dimensión</sidenav-router-link>
      <sidenav-router-link to="/reports/data/list" :exact="true">Gráficos genéricos</sidenav-router-link>
    </sidenav-menu>
-->

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      v-if="hasRole(['ADM'])"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/reports') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i
          class="menu-icon fa fa-chart-pie  fa-fw justify-content-center mr-2"
        ></i>
        <span class="menu-text">Reportes</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Reportes</span>
            </span>
          </li>

          <router-link
            to="/reports/dimension/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Dimensiones</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/reports/data/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Gráficos Genéricos</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      v-if="hasRole(['ADM'])"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/region/list') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i
          class="menu-icon fa fa-map-marker-alt fa-fw justify-content-center mr-2"
        ></i>
        <span class="menu-text">Regiones</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Regiones</span>
            </span>
          </li>

          <router-link
            to="/region/list/region"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Regiones</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/region/list/subRegion"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Subregiones</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!--<li
      class="menu-item menu-item-submenu"
      aria-haspopup="true"
      data-menu-toggle="hover"
    >
      <a href="javascript:;" class="menu-link menu-toggle">
        <span><i class="menu-icon la la-info"></i></span>
        <span class="menu-text">Incidentes</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu" kt-hidden-height="480" style="">
        <i class="menu-arrow"></i>
        <ul class="menu-subnav">
          <li class="menu-item menu-item-parent" aria-haspopup="true">
            <span class="menu-link">
              <span class="menu-text">Miscellaneous</span>
            </span>
            <router-link
              to="/incident/list/localVotacionActosViolencia"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon la la-hand-rock-o"></i>
                  <span class="menu-text">Actos de Violencia</span>
                </a>
              </li>
            </router-link>
          </li>
          <li class="menu-item menu-item-parent" aria-haspopup="true">
            <router-link
              to="/incident/list/localVotacionIncidenteOtros"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon la la-clone"></i>
                  <span class="menu-text">Otros</span>
                </a>
              </li>
            </router-link>
          </li>
        </ul>
      </div>
    </li>-->

    <!--
    <li class="menu-section">
      <h4 class="menu-text">Components</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }">
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-digital-marketing"></i>
        <span class="menu-text">Vue Bootstrap</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Vue Bootstrap</span>
            </span>
          </li>

          <router-link to="/vue-bootstrap/alert" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Alert</span>
              </a>
            </li>
          </router-link>

          <router-link to="/vue-bootstrap/badge" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Badge</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    -->
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  data: () => ({
    rol: null,
  }),
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    hasRole(roles) {
      if (!roles || roles.length == 0) {
        return true;
      }
      debugger;
      return roles.indexOf(this.rol) !== -1;
    },
  },
  mounted: function() {
    this.rol = this.$session.get("CodigoRol");
  },
};
</script>
