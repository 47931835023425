import LayoutPage from '@/view/layout/Layout'

export default [{
    path: '/user',
    component: LayoutPage,
    children: [{
        path: 'list/usuarios',
        name: 'list-users',
        component: () => import('@/view/user/ListUsers'),
    }]
}]