<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <div class="d-flex align-items-center justify-content-between dv-footer-sl" v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }">
      <!-- <div class="text-dark">
        <span class="text-muted font-weight-bold mr-2">
          2021 &nbsp;&copy;&nbsp;
        </span>
        <a href="http://stratlabs.pe/" target="_blank" class="text-dark-75 text-hover-primary">
          StratLabs
        </a>
      </div> -->
      <a href="https://stratlabs.pe" target="_blank">
        <img :src="publicResource('media/logos/stratlabs.svg')" class="img-fluid img-sl-footer" />
        <span class="ml-1 mt-1 name-sl-footer" style=""><span class="sp-footer-sl-1">STRAT</span><span class="sp-footer-sl-2">LABS</span></span>
      </a>
    </div>
  </div>
  <!-- end:: Footer -->
</template>
<style>
.img-sl-footer {
  width: 13px;
  height: auto;
}

.name-sl-footer {
  font-size: 0.85rem;
  font-weight: 600;
}

.dv-footer-sl {
  filter: grayscale();
}

.dv-footer-sl:hover {
  filter: none;
}

.dv-footer-sl .sp-footer-sl-2 {
  color: #8c8c8e;
}

.dv-footer-sl:hover .sp-footer-sl-2 {
  color: #3eb4e4;
  font-weight: 800;
}
.sp-footer-sl-1 {
  color: #a3a4a6 !important;
}
</style>
<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
  },
};
</script>
