import LayoutPage from "@/view/layout/Layout";

export default [
  {
    path: "/spending",
    component: LayoutPage,
    children: [
      {
        path: "list/ElectoralProcessSpendingUser",
        name: "list-electoral-process-user-expenditure",
        component: () => import("@/view/spending/ListProcesoElectoralGastoUsuario"),
      },{
        path: "list/ElectoralProcessSpendingAdministration",
        name: "list-electoral-process-administration-expenditure",
        component: () => import("@/view/spending/ListProcesoElectoralGastoAdministracion"),
      }
      
    ],
  },
];
