<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-2 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside order-1 order-lg-2 d-flex flex-row-auto position-relative overflow-hidden"
      >
        <!--begin: Aside Container-->
        <div
          class="d-flex flex-column-fluid flex-column justify-content-between py-9 px-7 py-lg-13 px-lg-20"
        >
          <!--begin::Logo-->

          <!--end::Logo-->
          <!--begin::Aside body-->
          <div class="d-flex flex-column-fluid flex-column flex-center">
            <div class="login-form ">
              <form class="form" novalidate="novalidate" id="frmLogin">
                <div href="#" class="text-center pb-15 pt-5">
                  <img
                    :src="publicResource('media/transparencia/logo-transp.png')"
                    class="max-h-75px"
                    alt=""
                  />
                </div>
                <div class="pb-13 pt-lg-0 pt-5">
                  <h3
                    class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                  >
                    Bienvenido a SEGEL
                  </h3>
                  <span class="text-muted font-weight-bold font-size-h4"
                    >Una plataforma de Transparencia</span
                  >
                </div>
                <div class="form-group">
                  <label class="font-size-h6 font-weight-bolder text-dark"
                    >Usuario</label
                  >
                  <div>
                    <input
                      class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="text"
                      v-model="email"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="d-flex justify-content-between mt-n5">
                    <label
                      class="font-size-h6 font-weight-bolder text-dark pt-5"
                      >Contraseña</label
                    >
                    <a
                      class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5 cursor-pointer"
                      @click="doShowRecover()"
                      >¿Recuperar contraseña?</a
                    >
                  </div>
                  <div>
                    <input
                      class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="password"
                      v-model="login.password"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="pb-lg-0 pb-5">
                  <button
                    ref="btnSubmitLogin"
                    @click.prevent="doLogin()"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                  >
                    Iniciar sesión
                  </button>
                </div>
              </form>
            </div>
          </div>
          <!--end::Aside body-->
          <!--begin: Aside footer for desktop-->
          <div class="text-center">
            <div class="mb-3 font-weight-bold">Con el apoyo de:</div>
            <img
              :src="publicResource('media/logos/PNUD.png')"
             style="height:150px;width:auto"
              alt=""
            />
          </div>
          <!--end: Aside footer for desktop-->
        </div>
        <!--end: Aside Container-->
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="content order-2 order-lg-1 d-flex flex-column w-100 p-0"
        style="background-color: #fff"
      >
        <!--begin::Title-->
        <!-- <div class="d-flex flex-column justify-content-center text-center pt-lg-40 pt-md-5 pt-sm-5 px-lg-0 pt-5 px-7">
          <h3 class="display4 font-weight-bolder my-7 text-dark" style="color: #986923;">Amazing Wireframes</h3>
          <p class="font-weight-bolder font-size-h2-md font-size-lg text-dark opacity-70">User Experience &amp; Interface Design, Product Strategy <br />Web Application SaaS Solutions</p>
        </div>-->
        <!--end::Title-->
        <!--begin::Image-->
        <div
          class="content-img d-flex flex-row-fluid bgi-size-cover bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="
            'background-image: url(' +
              publicResource('media/transparencia/fondo-arte-alta-2.jpg') +
              ');'
          "
        ></div>

        <!--end::Image-->
      </div>
      <!--end::Content-->
    </div>

    <!--
    <div class="login login-2 d-flex flex-column flex-lg-row flex-column-fluid bg-white">
      <div class="login-aside d-flex flex-column flex-row-auto" style="background-color: #134a88;">
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img :src="publicResource('media/transparencia/logo-yellow-transp.png')" class="max-h-70px" alt="" />
          </a>
          <h3 class="font-weight-bolder text-center font-size-h4 font-size-h1-lg" style="color: #ffdc00;">Sistema de<br />Seguimiento Electoral</h3>
        </div>
        <div class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center" :style="{ backgroundImage: `url(${backgroundImage})` }"></div>
      </div>

      <div class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div class="d-flex flex-column-fluid flex-center">

          <div class="login-form ">
            <form class="form" novalidate="novalidate" id="frmLogin">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                  Bienvenido a SEGEL
                </h3>
                <span class="text-muted font-weight-bold font-size-h4">Una plataforma de transparencia</span>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">Usuario</label>
                <div>
                  <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="text" v-model="email" />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5">Contraseña</label>
                  <a class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5 cursor-pointer" @click="doShowRecover()">¿Recuperar contraseña?</a>
                </div>
                <div>
                  <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="password" v-model="login.password" autocomplete="off" />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button ref="btnSubmitLogin" @click.prevent="doLogin()" class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3">
                  Iniciar sesión
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="d-flex justify-content-lg-center flex-column justify-content-center align-items-center py-7 py-lg-0">
          <div class="mb-3">Con el apoyo de:</div>
          <img :src="publicResource('media/transparencia/logo-kas-transp.png')" class="max-h-50px" alt="" />

        
          <a href="#" class="text-primary font-weight-bolder font-size-h5">Terms</a>
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5">Plans</a>
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5">Contact Us</a>

        </div>

      </div>

    </div>
    -->

    <b-modal
      id="modal-recover-password"
      size="md"
      :no-close-on-backdrop="false"
      :no-close-on-esc="false"
      v-model="recoverPassword.showModal"
      centered
      v-if="recoverPassword.showModal"
    >
      <template slot="modal-title">
        <div>Recuperar <span class="font-weight-light">contraseña</span></div>
      </template>

      <template slot="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          @click.prevent="recoverPassword.showModal = false"
        >
          <i class="fa fa-ban"></i> Cancelar
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click.prevent="doSendRecover()"
        >
          <i class="fa fa-envelope"></i> Enviar
        </button>
      </template>

      <div class="pb-5 pt-lg-0 pt-5">
        <!--<h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
          Bienvenido a SEGEL
        </h3>-->
        <span class="text-muted font-weight-bold font-size-h4"
          >Ingrese su correo electrónico. Recibirá una contraseña temporal que
          le permitirá ingresar.</span
        >
      </div>
      <div class="form-group mb-0">
        <label class="font-size-h6 font-weight-bolder text-dark"
          >Correo electrónico</label
        >
        <div>
          <input
            class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
            type="text"
            v-model="email"
          />
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-change-password"
      size="md"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      v-model="changePassword.showModal"
      centered
      v-if="changePassword.showModal"
    >
      <template slot="modal-title">
        <div>Cambiar <span class="font-weight-light">contraseña</span></div>
      </template>

      <template slot="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          @click.prevent="doChangePassword()"
        >
          <i class="fa fa-save"></i> Cambiar contraseña
        </button>
      </template>

      <div class="pb-5 pt-lg-0 pt-5">
        <!--
          <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
            Bienvenido a SEGEL
          </h3>
          <span class="text-muted font-weight-bold font-size-h4">Ingrese su correo electrónico. Recibirá una contraseña temporal que le permitirá ingresar.</span>
        -->
        <div>
          <div
            class="font-weight-bold font-size-h4"
            :class="[passwordValidMinChar ? 'text-success' : 'text-muted']"
          >
            <i
              class="fa"
              :class="[
                passwordValidMinChar ? 'fa-check text-success' : 'fa-times',
              ]"
            ></i>
            Mínimo 8 caracteres
          </div>
          <div
            class="font-weight-bold font-size-h4"
            :class="[passwordValidUpperLower ? 'text-success' : 'text-muted']"
          >
            <i
              class="fa"
              :class="[
                passwordValidUpperLower ? 'fa-check text-success' : 'fa-times',
              ]"
            ></i>
            Contiene mayúsculas y minúsculas
          </div>
          <div
            class="font-weight-bold font-size-h4"
            :class="[passwordValidRepeat ? 'text-success' : 'text-muted']"
          >
            <i
              class="fa"
              :class="[
                passwordValidRepeat ? 'fa-check text-success' : 'fa-times',
              ]"
            ></i>
            Repetir contraseña
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="font-size-h6 font-weight-bolder text-dark"
          >Nueva contraseña</label
        >
        <div>
          <input
            class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
            type="password"
            v-model="changePassword.newPassword"
          />
        </div>
      </div>
      <div class="form-group mb-0">
        <label class="font-size-h6 font-weight-bolder text-dark"
          >Repetir contraseña</label
        >
        <div>
          <input
            class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
            type="password"
            v-model="changePassword.rePassword"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-2.scss";
</style>

<style>
body.swal2-height-auto {
  height: 100% !important;
}
</style>

<script>
import Vue from "vue";
import Swal from "sweetalert2";

export default {
  name: "login",
  data() {
    return {
      email: "",

      login: {
        password: "",
      },
      recoverPassword: {
        showModal: false,
      },
      changePassword: {
        usuarioId: null,
        tempPassword: "",
        newPassword: "",
        rePassword: "",
        showModal: false,
      },
    };
  },
  computed: {
    backgroundImage() {
      return (
        process.env.BASE_URL +
        "media/transparencia/undraw_election_day_w842.svg"
      );
      //return process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg";
    },

    passwordValidMinChar: function() {
      return this.changePassword.newPassword.length >= 8;
    },
    passwordValidUpperLower: function() {
      debugger;
      return (
        this.changePassword.newPassword.length > 0 &&
        this.changePassword.newPassword.toLowerCase() !=
          this.changePassword.newPassword &&
        this.changePassword.newPassword !=
          this.changePassword.newPassword.toUpperCase()
      );
    },

    passwordValidRepeat: function() {
      return (
        this.changePassword.newPassword.length > 0 &&
        this.changePassword.newPassword == this.changePassword.rePassword
      );
    },
  },
  mounted() {},
  methods: {
    doLogin: function() {
      const submitButton = this.$refs["btnSubmitLogin"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      var model = {
        usuario: this.email,
        password: this.login.password,
      };
      this.$http.post(this.apiRoute("Account", "Login"), model).then(
        function(response) {
          debugger;
          if (response.body.error) {
            Swal.fire({
              icon: "error",
              title: "",
              text: response.body.message,
            });
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          } else if (!response.body.result) {
            Swal.fire({
              icon: "info",
              title: "",
              text: response.body.message,
            });
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          } else if (response.body.data.esTemporal) {
            Swal.fire({
              icon: "success",
              title: "Cambio de contraseña",
              text: response.body.message,
            });

            this.changePassword.usuarioId = response.body.data.usuarioId;
            this.changePassword.tempPassword = this.login.password;
            this.changePassword.newPassword = "";
            this.changePassword.rePassword = "";
            this.changePassword.showModal = true;

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          } else {
            Swal.fire({
              icon: "success",
              title: "¡Bienvenido!",
              text: response.body.message,
              showCancelButton: false,
              showConfirmButton: false,
              timer: 2000,
            });

            this.$session.start();

            this.$session.set("UserName", response.body.data.userName);
            this.$session.set("UsuarioId", response.body.data.usuarioId);
            this.$session.set("RolId", response.body.data.rolId);
            this.$session.set("CodigoRol", response.body.data.codigoRol);
            this.$session.set("RolNombre", response.body.data.rolNombre);
            this.$session.set("Nombres", response.body.data.nombres);
            this.$session.set(
              "ApellidoPaterno",
              response.body.data.apellidoPaterno
            );
            this.$session.set(
              "ApellidoMaterno",
              response.body.data.apellidoMaterno
            );
            this.$session.set(
              "CorreoElectronico",
              response.body.data.correoElectronico
            );

            this.$session.set("Celular", response.body.data.celular);
            this.$session.set(
              "TipoDocumento",
              response.body.data.tipoDocumento
            );
            this.$session.set(
              "NumeroDocumento",
              response.body.data.numeroDocumento
            );
            this.$session.set("RutaFoto", response.body.data.rutaFoto);

            this.$session.set(
              "ProcesoElectoralId",
              response.body.data.procesoElectoralActualId
            );
            this.$session.set(
              "ProcesoElectoral",
              response.body.data.procesoElectoralActual
            );

            this.$session.set("token", response.body.data.token);
            this.$session.set("jwt", response.body.data.token);

            Vue.http.headers.common["Authorization"] =
              "Bearer " + response.body.data.token;

            //Vue.prototype.startSignalR(response.body.data.token);

            this.$router.push({ name: "home-dashboard-user" });

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          }
        },
        function(error) {
          Swal.fire("", this.defaultErrorMessage, "error");
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }
      );
    },
    doShowRecover: function() {
      this.recoverPassword.showModal = true;
    },

    doSendRecover: function() {
      var model = {
        CorreoElectronico: this.email,
      };

      this.$http
        .post(this.apiRoute("Account", "ObtenerPasswordTemporal"), model)
        .then(
          function(response) {
            debugger;
            if (response.body.error) {
              Swal.fire({
                icon: "error",
                title: "",
                text: response.body.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "",
                text: response.body.message,
              });
              this.recoverPassword.showModal = false;
            }
          },
          function(error) {
            Swal.fire("", this.defaultErrorMessage, "error");
          }
        );
    },

    doChangePassword: function() {
      if (!this.passwordValidMinChar) {
        Swal.fire(
          "",
          "La contraseña debe tener al menos 8 caracteres.",
          "info"
        );
        return;
      }

      if (!this.passwordValidUpperLower) {
        Swal.fire(
          "",
          "La contraseña debe contener mayúsculas y minúsculas.",
          "info"
        );
        return;
      }

      if (!this.passwordValidRepeat) {
        Swal.fire("", "La contraseña no coincide.", "info");
        return;
      }

      this.showLoading();

      var model = {
        usuarioId: this.changePassword.usuarioId,
        tempPassword: this.changePassword.tempPassword,
        password: this.changePassword.newPassword,
        passwordConfirm: this.changePassword.rePassword,
      };

      this.$http
        .post(this.apiRoute("Account", "CambiarPasswordTemporal"), model)
        .then(
          function(response) {
            if (response.body.error) {
              Swal.fire({
                icon: "error",
                title: "",
                text: response.body.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "",
                text: response.body.message,
              });
              //this.changePassword.showModal = false;
              this.login.password = this.changePassword.newPassword;
              this.doLogin();
            }
          },
          function(error) {
            Swal.fire("", this.defaultErrorMessage, "error");
          }
        );
    },
  },
};
</script>
