import LayoutPage from '@/view/layout/Layout'

export default [{
    path: '/sheet',
    component: LayoutPage,
    children: [{
        path: 'local/edit/:procesoElectoralId/:localVotacionId/:turno?',
        name: 'sheet-local-edit',
        component: () => import('@/view/sheet/EditFichaLocal'),
        props: (route) => {
            return {
                localVotacionId: Number.parseInt(route.params.localVotacionId, 10),
                procesoElectoralId: Number.parseInt(route.params.procesoElectoralId, 10),
                turno: route.params.turno ? Number.parseInt(route.params.turno, 10) : null,
            }
        }
    }]
}]
