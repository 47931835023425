import LayoutPage from '@/view/layout/Layout'

export default [{
    path: '/reports',
    component: LayoutPage,
    children: [{
        path: 'dimension/list',
        component: () => import('@/view/reports/ListDimension')
    },
    {
        name: 'reports-dimension-edit',
        path: 'dimension/edit/:reporteDimensionId/:reporteDimensionConfiguracionId?',
        component: () => import('@/view/reports/EditDimension')
    },
    {
        path: 'data/list',
        component: () => import('@/view/reports/ListData')
    },
    {
        name: 'reports-data-edit',
        path: 'data/edit/:reporteDataId?',
        component: () => import('@/view/reports/EditData')
    },
    {
        name: 'reports-data-view',
        path: 'data/view/:reporteDataId',
        component: () => import('@/view/reports/ViewData')
    }]
}]
