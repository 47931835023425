export default function () {
    return {

        doLogout() {
            this.$session.destroy()
            this.$router.push('/')
        },

        // Public url
        publicUrl: process.env.BASE_URL,
        apiUrl: process.env.NODE_ENV === 'production' ? '//voluntariadotransparencia.com/segel-api/api/' : 'https://localhost:44398/api/',
        filesUrl: process.env.NODE_ENV === 'production' ? '//voluntariadotransparencia.com/segel-api/' : 'https://localhost:44398/',
        notifyHubUrl: process.env.NODE_ENV === 'production' ? '//voluntariadotransparencia.com/segel-api/notify-hub/' : 'https://localhost:44398/notify-hub/',
        commonBaseUrl: process.env.NODE_ENV === 'production' ? '//voluntariadotransparencia.com/segel-api/' : 'https://localhost:44398/',

        publicResource(path) {
            return this.publicUrl + path;
        },

        apiRoute(controller, action) {
            return this.apiUrl + controller + '/' + action;
        },

        apiResource(path) {
            return this.filesUrl + path;
        },

        commonUrlResource(path) {
            return this.commonBaseUrl + path;
        },
    }
}
