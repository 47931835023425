import LayoutPage from '@/view/layout/Layout'

export default [{
    path: '/home',
    component: LayoutPage,
    children: [{
        path: 'dashboard',
        name: 'home-dashboard',
        component: () => import('@/view/home/Dashboard')
    }, {
        path: 'dashboard-admin',
        name: 'home-dashboard-admin',
        component: () => import('@/view/home/DashboardAdmin')
    }, {
        path: 'dashboard-user',
        name: 'home-dashboard-user',
        component: () => import('@/view/home/DashboardUser')
    }]
}]
