import LayoutPage from '@/view/layout/Layout'

export default [{
    path: '/process',
    component: LayoutPage,
    children: [{
        path: 'list/ElectoralProcess',
        name: 'list-electoral-process',
        component: () => import('@/view/process/ListProcesoElectoral'),

    }]
}]