import LayoutPage from '@/view/layout/Layout'

export default [{
    path: '/incident',
    component: LayoutPage,
    children: [{
        path: 'list/localVotacionActosViolencia',
        name: 'list-actos-violencia',
        component: () => import('@/view/incident/ListActosViolencia'),
    }, {
        path: 'list/localVotacionIncidenteOtros',
        name: 'list-incidente-otros',
        component: () => import('@/view/incident/ListIncidenteOtros'),
        }]
}]