import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

//Documentation:
//https://github.com/DaniJG/so-signalr

export default {
    install(Vue, options) {
        // use a new Vue instance as the interface for Vue components to receive/send SignalR events
        // this way every component can listen to events or send new events using this.$notifyHub
        const notifyHub = new Vue()
        Vue.prototype.$notifyHub = notifyHub

        // Provide methods to connect/disconnect from the SignalR hub
        let connection = null
        let startedPromise = null
        let manuallyClosed = false

        Vue.prototype.startSignalR = (jwtToken) => {
            connection = new HubConnectionBuilder()
                .withUrl(
                    options.url,
                    jwtToken ? { accessTokenFactory: () => jwtToken } : null
                )
                .configureLogging(LogLevel.Information)
                .build()

            // Forward hub events through the event, so we can listen for them in the Vue components
            // connection.on('FichaGroupUpdated', (procesoElectoralId, localVotacionId, turno) => {
            //     notifyHub.$emit('ficha-updated', { procesoElectoralId, localVotacionId, turno })
            // })

            // connection.on('IncidenteRegistrado', (tipo, localNombre, localDireccion, localSubRegion, localRegion) => {
            //     console.log('connection recieved: IncidenteRegistrado')
            //     notifyHub.$emit('incidente-registrado', { tipo, localNombre, localDireccion, localSubRegion, localRegion })
            // })

            /*connection.on('QuestionAdded', (question) => {
                notifyHub.$emit('question-added', question)
            })
            connection.on('QuestionScoreChange', (questionId, score) => {
                notifyHub.$emit('score-changed', { questionId, score })
            })
            connection.on('AnswerCountChange', (questionId, answerCount) => {
                notifyHub.$emit('answer-count-changed', { questionId, answerCount })
            })
            connection.on('AnswerAdded', answer => {
                notifyHub.$emit('answer-added', answer)
            })
            connection.on('LiveChatMessageReceived', (username, text) => {
                notifyHub.$emit('chat-message-received', { username, text })
            })*/

            // You need to call connection.start() to establish the connection but the client wont handle reconnecting for you!
            // Docs recommend listening onclose and handling it there.
            // This is the simplest of the strategies
            function start() {
                startedPromise = connection.start()
                    .catch(err => {
                        console.error('Failed to connect with hub', err)
                        return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000))
                    })
                return startedPromise
            }
            connection.onclose(() => {
                //if (!manuallyClosed) start()
            })

            // Start everything
            manuallyClosed = false
            //start()
        }

        Vue.prototype.stopSignalR = () => {
            if (!startedPromise) return

            manuallyClosed = true
            return startedPromise
                .then(() => connection.stop())
                .then(() => { startedPromise = null })
        }

        // Provide methods for components to send messages back to server
        // Make sure no invocation happens until the connection is established
        notifyHub.subscribeFichaGroup = (groupKey) => {
            if (!startedPromise) return

            return startedPromise
                .then(() => connection.invoke('SubscribeFichaGroup', groupKey))
                .catch(console.error)
        }
        notifyHub.unsubscribeFichaGroup = (groupKey) => {
            if (!startedPromise) return

            return startedPromise
                .then(() => connection.invoke('UnsubscribeFichaGroup', groupKey))
                .catch(console.error)
        }
        notifyHub.notificarCambioFicha = (procesoElectoralId, localVotacionId, turno) => {
            if (!startedPromise) return

            return startedPromise
                .then(() => connection.invoke('NotificarCambioFicha', { procesoElectoralId, localVotacionId, turno }))
                .catch(console.error)
        }

        notifyHub.notificarIncidenteRegistrado = (procesoElectoralId, localVotacionId, tipo, entidadId) => {
            if (!startedPromise) return

            return startedPromise
                .then(() => connection.invoke('NotificarIncidenteRegistrado', { procesoElectoralId, localVotacionId, tipo, entidadId }))
                .catch(console.error)
        }
    }
}
