import Vue from "vue";
import Router from "vue-router";

import account from './account'
import home from './home'
import sheet from './sheet'
import sheetApp from './sheetApp'
import location from './location'
import user from './user'
import incident from './incident'
import processelectoral from './process'
import reports from './reports'
import region from './region'
import propaganda from './electoralpropaganda'
import multimedia from './multimedia'
import spending from './spending'

Vue.use(Router);

const ROUTES = [{ path: '', redirect: '/login' }]
    .concat(account)
    .concat(home)
    .concat(sheet)
    .concat(sheetApp)
    .concat(location)
    .concat(user)
    .concat(incident)
    .concat(processelectoral)
    .concat(reports)
    .concat(region)
    .concat(propaganda)
    .concat(multimedia)
    .concat(spending)

export default new Router({
    base: process.env.BASE_URL,
    mode: 'history',
    routes: ROUTES
});
